import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Header from "./Header/Header";
import About from "./About/About";
import Tag from "./Tagline/Tagline";
import Join from "./Join/Join";
import ContentComponent from "./ContentComponent/ContentComponent";
function App() {
  return (
    <div class="App">
      <Header>
        <img src={logo} alt="logo" />
      </Header>
      <ContentComponent>
        <Tag> </Tag>
      </ContentComponent>
      <div class="box">
        <About> </About>
      </div>
      <div class="box">
        <Join> </Join>
      </div>
    </div>
  );
}

export default App;
