import React from "react";
import "./Join.css";

const Join = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <h1 class="heading">JOIN US</h1>
      <p id="text">
        We are seeking creative and entrepreneurial people to join our
        fast-paced team. We are focused on growing and developing an assortment
        of brands with the best possible product experience for our customers.
        Our team is growing and if you are keen on retail and e-commerce with a
        willingness to work in a dynamic environment, we might be a great match.
      </p>
      <button id="join">Join Us</button>
    </div>
  );
};

export default Join;
