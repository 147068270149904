import React from 'react'
import './Tagline.css'
const Tag = () => {
    return(
    <div class = 'tag_'>
     <h1 class = 'tag'>TAGLINE</h1>
    </div>
    )
}

export default Tag