// import React from "react";
// import "./Header.css";
// import logo from "../logo.svg";
// const Header = () => {
//   return (
//     <div>
//       <nav class="nav-container">
//         <div class="row">
//           <div class="logo-container">
//             <div class="logo">
//               {/* <img src={logo} alt="logo" class="logo_" /> */}
//               <a href="/" />
//             </div>
//           </div>
//           <div class="nav">
//             <a href="#"> About Us </a>|<a href="#"> Products </a>|
//             <a href="#"> Join Us </a>|<a href="#"> Why Us </a>|
//             <a href="#"> Contact </a>
//           </div>
//         </div>
//       </nav>
//     </div>
//   );
// };

// export default Header;

import React from "react";
import "./Header.css";
import logo from "../logo.svg";

let lastScrollY = 0;

class Header extends React.Component {
  state = {
    toggle: false
  };
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  nav = React.createRef();
  logo = React.createRef();

  handleScroll = () => {
    lastScrollY = window.scrollY;

    if (lastScrollY > 135) {
      this.setState({ toggle: true });
      this.nav.current.classList.add("tja-header-fixed");
      this.logo.current.classList.add("tja-header-trans");
    } else {
      this.setState({ toggle: false });
      this.nav.current.classList.remove("tja-header-fixed");
      this.logo.current.classList.remove("tja-header-trans");
    }
  };

  render() {
    return (
      <div ref={this.nav} className="tja-header-container">
        <div className="tja-header-row">
          <div ref={this.logo} className="tja-header-col-1">
            <img src={logo} alt="logo" />
          </div>
          <div className="tja-header-col-2">
            <ul style={{ color: this.state.toggle ? "#263238" : "white" }}>
              <li>About Us &nbsp; |</li>
              <li>Products &nbsp; |</li>
              <li>Join Us &nbsp; |</li>
              <li>Why Us &nbsp; |</li>
              <li>Contact &nbsp;</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
