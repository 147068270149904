import React from "react";
import "./About.css";
const About = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <h1 class="heading"> ABOUT US </h1>
      <p id="text">
        TJ&A global Online Private Limited is a retail startup focused to create
        unique value proposition for its customers. The vision is to be a one
        stop destination in the space of fashion and home furnishings.
        Currently, there are two brands under the umbrella of TJ&A - ‘REDEARTH’
        and ‘STUDIO 99’. We are all set to launch the brands in the US online
        space.
      </p>
      <button id="sub">Subscribe</button>
    </div>
  );
};

export default About;
